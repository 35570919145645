import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { LoginCallback, Security } from "@okta/okta-react";
import React, { lazy } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";

import { ORDER_ADMIN } from "./Auth/AuthCheck";
import NavMenu from "./Common/NavMenu/NavMenu";
import Landing from "./Components/Landing/Landing";
import Login from "./Components/Login/Login";
import ProtectedRoute from "./ProtectedRoute/ProtectedRoute";

import { oktaAuthConfig, oktaSignInConfig } from "./config";

import "./App.css";

const MyPathology = lazy(() => import("./Components/MyPathology/MyPathology"));
const Orders = lazy(() => import("./Components/Orders/Orders"));
const Requisition = lazy(() => import("./Components/Requisition/Requisition"));
const RequisitionCart = lazy(() =>
  import("./Components/Requisition/RequisitionCart")
);
const Overview = lazy(() => import("./Components/Overview/Overview"));

/*
----------------------------- Notes -------------------------------------
1. Applied Okta Sign-in widget with route protection. 
2. Removed `global-cookie` implementation as Okta has built-in token manager.
*/

const oktaAuth = new OktaAuth(oktaAuthConfig);

function App() {
  const navigate = useNavigate();

  const originalUri = async (_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  /* 
    1. onAuthRequired with customAuthHandler is required when using <SecureRoute> component.
    2. Since the <SecureRoute> isn't yet supported with react-router-dom v6 this can be safely ignored.
  */

  return (
    <div className="App">
      <Security oktaAuth={oktaAuth} restoreOriginalUri={originalUri}>
        <NavMenu />
        <main>
          <Routes>
            <Route
              path="/"
              element={<ProtectedRoute authorized={[ORDER_ADMIN]} />}
            >
              <Route path="" element={<Landing />} />
            </Route>
            <Route
              path="/login"
              element={<Login config={oktaSignInConfig} />}
            />
            <Route path="/login/callback" element={<LoginCallback />} />
            <Route
              path="/overview"
              element={<ProtectedRoute authorized={[ORDER_ADMIN]} />}
            >
              <Route path="" element={<Overview />} />
            </Route>
            <Route
              path="/requisition"
              element={<ProtectedRoute authorized={[ORDER_ADMIN]} />}
            >
              <Route path="" element={<RequisitionCart />} />
            </Route>
            <Route
              path="/addrequisition"
              element={<ProtectedRoute authorized={[ORDER_ADMIN]} />}
            >
              <Route path="" element={<Requisition />} />
            </Route>
            <Route
              path="/orders"
              element={<ProtectedRoute authorized={[ORDER_ADMIN]} />}
            >
              <Route path="" element={<Orders />} />
            </Route>
            <Route
              path="/mypathology"
              element={<ProtectedRoute authorized={[ORDER_ADMIN]} />}
            >
              <Route path="" element={<MyPathology />} />
            </Route>
          </Routes>
        </main>
      </Security>
    </div>
  );
}

export default App;
