import React from "react";
import { Link, NavLink, useLocation } from "react-router-dom";

import logo from "../../Assets/Images/logo-iron-mountain.svg";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

import "./NavMenu.css";

/*
----------------------------- Notes -------------------------------------
1. Updated NavMenu with horizontal (desktop view) and vertical (mobile view) menu-items.
2. NavLink, Link components of react-router-dom used for highlighting the activate menu-item, 
navigate back to landing page on brand click.
3. Made the nav-bar container responsive to view-port widths rather fluid. 
*/

const NavMenu = () => {
  const location = useLocation();
  const activeNavItem = ({ isActive }) =>
    `nav-link ${isActive ? "active nav-link-padding" : "nav-link-padding"}`;

  return (
    <header>
      <nav className="navbar fixed-top navbar-expand-sm box-shadow-border bg-white py-0">
        <div className="container py-0 navbar-min-height">
          <Link className="navbar-brand py-0" to="/">
            <img className="logo" src={logo} alt="" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          {location.pathname !== "/login" && (
            <div
              className="collapse navbar-collapse ms-sm-5"
              id="navbarNavDropdown"
            >
              <ul className="navbar-nav">
                {/* <li className="nav-item">
                <NavLink className={activeNavItem} to="/overview">
                  Overview
                </NavLink>
              </li> */}
                <li className="nav-item my-pathology-width">
                  <NavLink className={activeNavItem} to="/mypathology">
                    My Pathology
                  </NavLink>
                </li>
                <li className="nav-item requisition-width">
                  <NavLink className={activeNavItem} to="/addrequisition">
                    Add Requisition
                  </NavLink>
                </li>
                <li className="nav-item cart-width">
                  <NavLink className={activeNavItem} to="/requisition">
                    Cart
                  </NavLink>
                </li>
                <li className="order-width">
                  <NavLink className={activeNavItem} to="/orders">
                    Orders
                  </NavLink>
                </li>
              </ul>
            </div>
          )}
        </div>
      </nav>
    </header>
  );
};

export default NavMenu;
