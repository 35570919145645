import { useOktaAuth } from "@okta/okta-react";
import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import SignInWidget from "../Okta/SignInWidget";

const Login = ({ config }) => {
  const { oktaAuth, authState } = useOktaAuth();
  const location = useLocation();

  const onSuccess = (tokens) => {
    oktaAuth.handleLoginRedirect(tokens, location.state?.path);
  };

  const onError = (error) => {
    console.log("Error logging in", error);
  };

  return (
    <>
      {/* If authenticated redirect to root page */}
      {authState?.isAuthenticated ? (
        <Navigate to="/" replace />
      ) : (
        <SignInWidget config={config} onSuccess={onSuccess} onError={onError} />
      )}
    </>
  );
};

export default Login;
