/*class DateFunctions {
    // Simple function
    function dateToString = (date) => {
  
      if (date === null) {
        return null;
      }
      const dateObj = new Date(date)
      const month = dateObj.getMonth() + 1
      return dateObj.getFullYear() + '-' + month + '-' + dateObj.getDate()
    }
    
  }
  export default DateFunctions*/

export function dateToMmddyyyyString(date) {
  if (date === null) {
    return null;
  }
  const utcDate = new Date(date);
  const dateObj = new Date(
    utcDate.getTime() + utcDate.getTimezoneOffset() * 60000
  );
  const month = dateObj.getMonth() + 1;
  return month + "-" + dateObj.getDate() + "-" + dateObj.getFullYear();
}

export function dateToyyyymmddString(date) {
  if (date === null) {
    return null;
  }
  const dateObj = new Date(date);
  const month = `0${dateObj.getMonth() + 1}`.slice(-2);
  const day = `0${dateObj.getDate()}`.slice(-2);
  return `${dateObj.getFullYear()}-${month}-${day}`;
}

/* 
Empty check for null, undefined, object, array, string and number
*/
export function isEmpty(val) {
  if (val === null || val === undefined || typeof val === "number") {
    return !!!val;
  }
  if (Array.isArray(val)) {
    return !!!val.length;
  }
  if (typeof val === "object") {
    return Object.keys(val).length === 0;
  }
  if (typeof val === "string") {
    return !val.trim();
  }
  throw new Error("unknown type");
}
