import React, { Suspense, useLayoutEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import { useDpodUser } from "../Common/Hooks/useDpodUser";
import Spinner from "../Common/Spinner/Spinner";

import NotAuthorized from "../Auth/NotAuthorized";
import { isEmpty } from "../Common/Utilities/helperFunctions";

/*
----------------------------- Notes -------------------------------------
1. Protected route preventing unauthorized access to privileged sections 
*/

const ProtectedRoute = ({ authorized, children }) => {
  // State Variables
  const [, setHasDpodUser] = useState(false);

  // Custom Hooks
  const location = useLocation();
  const { authState } = useDpodUser();

  const dpodUser = authState?.dpodUser;

  // Hooks
  useLayoutEffect(() => {
    if (dpodUser !== undefined) {
      setHasDpodUser(true);
    }
  }, [dpodUser]);

  // On waiting for authState to initialize
  if (isEmpty(authState)) {
    return (
      <div className="my-5">
        <Spinner />
      </div>
    );
  }

  // On !authState?.isAuthenticated navigate to Login page
  if (!authState?.isAuthenticated) {
    return <Navigate to="/login" state={{ path: location.pathname }} replace />;
  }

  /* 
  On waiting for the authenticated user profile, only undefined check is 
  enough for better user experience
  */
  if (dpodUser === undefined) {
    return (
      <div className="my-5">
        <Spinner />
      </div>
    );
  }

  /* 
  On authenticated user profile is null and path is root, rendering Outlet 
  for better user experience
  */
  if (location.pathname === "/") {
    return <Outlet />;
  }

  // On alidating the authorization of the user
  if (Array.isArray(dpodUser?.roles)) {
    const authorizedUser = dpodUser?.roles.some((role) => {
      return authorized.includes(role.code);
    });
    if (authorizedUser) {
      const userContext = [dpodUser, authState?.accessToken?.accessToken];
      return (
        <Suspense fallback={<></>}>
          {children ? children(userContext) : <Outlet context={userContext} />}
        </Suspense>
      );
    }
  }

  // On unauthorized
  return <NotAuthorized />;
};

export default ProtectedRoute;
