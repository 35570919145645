import { useOktaAuth } from "@okta/okta-react";
import { useLayoutEffect, useState } from "react";
import { GET } from "../../Services/io";

import { isEmpty } from "../Utilities/helperFunctions";

export const useDpodUser = () => {
  // State Variables
  const [, setHasDpodUser] = useState(false);

  // Custom Hooks
  const { authState } = useOktaAuth();

  const accessToken = authState?.accessToken?.accessToken;
  const claims = authState?.idToken?.claims;
  const dpodUser = authState?.dpodUser;

  // ------------------ IO -------------------
  const fetchDpodUser = async (oktaUserId, token) => {
    const response = await GET(`v1/users/${oktaUserId}`, { token });

    // Setting user data
    authState.dpodUser = response?.data;
    setHasDpodUser(true);
  };

  // Hooks
  useLayoutEffect(() => {
    // Fetch user profile once post authentication
    if (accessToken && claims && isEmpty(dpodUser)) {
      fetchDpodUser(claims.sub, accessToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, claims, dpodUser]);

  return { authState };
};
