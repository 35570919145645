import { useOktaAuth } from "@okta/okta-react";
import React from "react";

/*
----------------------------- Notes -------------------------------------
1. Landing page updated to host signin/signout buttons, prompting for signing
to see the data.
2. Once signed in user name is displayed with welcome note.
*/

const Landing = () => {
  const { oktaAuth, authState } = useOktaAuth();

  const loggingOut = async () => {
    // Local tokens will be removed before the logout redirect happens.
    await oktaAuth.signOut();
  };

  return (
    <div className="container">
      <div className="my-5 text-center">
        <h3>
          Welcome back,{" "}
          {authState?.oktaUser?.name || authState?.oktaUser?.given_name}
        </h3>
        <button type="button" className="btn btn-primary" onClick={loggingOut}>
          Logout
        </button>
      </div>
    </div>
  );
};

export default Landing;
