import React from "react";
import "./Spinner.css";

const Spinner = ({ size, className = "outerDiv fadein-quickly", message }) => {
  return (
    <div className={className}>
      <div
        style={
          size && {
            width: size,
            height: size,
            borderWidth: size / 10,
          }
        }
        className="spinner"
      ></div>
      {message ? <strong className="text-white mt-1">{message}</strong> : null}
    </div>
  );
};

export default Spinner;
