/*
----------------------------- Notes -------------------------------------
1. Removed `global-cookie` implementation as Okta has built-in token manager.
2. Building the headers with auth Bearer token and apiKey
*/

const handle401 = () => {
  window.location.href = "/login";
};

const buildHeaders = ({ token, handle, ...headers }) => {
  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    apikey: process.env.REACT_APP_CIAM_APIGEE_KEY,
    Custlocuserhandle: handle,
    headers,
  };
};

const fetchData = async (url, options) => {
  try {
    const response = await fetch(`/api/${url}`, options);

    if (response.status === 401) {
      handle401();
    }

    return await response.json();
  } catch (exception) {
    return exception;
  }
};

export const GET = async (url, headers) => {
  const options = {
    method: "GET",
    headers: buildHeaders(headers),
  };

  return await fetchData(url, options);
};

export const POST = async (url, headers, body) => {
  const options = {
    method: "POST",
    headers: buildHeaders(headers),
    body,
  };

  return await fetchData(url, options);
};

export const PUT = async (url, headers, body) => {
  const options = {
    method: "PUT",
    headers: buildHeaders(headers),
    body: JSON.stringify(body),
  };

  return await fetchData(url, options);
};

export const DELETE = async (url, headers) => {
  const options = {
    method: "DELETE",
    headers: buildHeaders(headers),
  };

  return await fetchData(url, options);
};

export const PATCH = async (url, headers, body) => {
  const options = {
    method: "PATCH",
    headers: buildHeaders(headers),
  };
  if (body) {
    options.body = body;
  }

  return await fetchData(url, options);
};
