import React from "react";
import "./Auth.css";

const NotAuthorized = () => {
  // Markup.....................................................................

  return (
    <div className="card unauthorized-container">
      <div className="card-body">
        <div className="text-center card-title">
          <h3>Not Authorized</h3>
        </div>
      </div>
    </div>
  );
};

export default NotAuthorized;
